@import 'scss';

body {
  margin: 0;
  padding: 0;
  background-color: #f1f6fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

body,
input,
button {
  font-family: 'Century Gothic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);

  &:visited,
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
}

label {
  font-family: 'Roboto', 'sans-serif';
}
