.page-wrapper {
  margin: 0 80px 24px;

  &__header {
    margin: 40px 0;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 999px) {
    margin: 0 40px 24px;
  }
}
