.p {
  &--italic {
    font-style: italic;
  }

  &--m-0 {
    margin: 0;
  }

  &--mr-8 {
    margin-right: 8px;
  }
}
