@import '../../scss/variables';

.breadcrumbs {
  &__item {
    font-family: Roboto, sans-serif !important;
    font-size: 16px !important;

    &--link {
      color: $blue_light !important;
    }
  }
}
