@import 'variables';

.tab-panel {
  margin-bottom: 20px;

  .MuiTabs-indicator {
    background-color: $blue_light;
  }

  &__item {
    font-family: Roboto, sans-serif !important;
    font-weight: bold !important;
    font-size: 16px !important;
    text-transform: none !important;

    &[aria-selected='true'] {
      color: $blue_light !important;
    }
  }
}
